<template>
  <PanelBox title="Contacten">
    <span slot="toolbar">
      <button
        type="button"
        class="toolbar-button"
        @click="createEntityContact"
      >
        <i class="fas fa-plus" /> Toevoegen
      </button>
    </span>
    <p v-if="!entityContacts.length" class="!tw-my-0">
      Er zijn geen gelinkte contacten.
    </p>
    <div v-else class="tw-grid md:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-4">
      <div
        v-for="entityContact in entityContacts"
        :key="entityContact.id"
        class="tw-relative tw-flex tw-gap-x-3 tw-rounded tw-border tw-p-2"
      >
        <div class="tw-absolute tw-flex tw-flex-col tw-gap-3 tw-right-0 tw-top-0 tw--translate-y-2 tw-translate-x-1/2">
          <button
            type="button"
            title="Bewerken"
            class="action-button tw-bg-white tw-text-success tw-border-success hover:tw-bg-success"
            @click="updateEntityContact(entityContact)"
          >
            <i class="fas fa-pencil" />
          </button>
          <button
            type="button"
            title="Verwijderen"
            class="action-button tw-bg-white tw-text-danger tw-border-danger hover:tw-bg-danger"
            @click="deleteEntityContact(entityContact)"
          >
            <i class="fas fa-trash" />
          </button>
        </div>
        <div>
          <span class="tw-font-bold">
            {{ entityContact.contact.display_name }}
            <router-link :to="{ name: 'ContactDetails', params: { id: entityContact.contact.id } }" target="_blank">
              <i class="fa fa-link" />
            </router-link>
          </span>
          <br>
          <span class="tw-italic tw-text-xs">{{ entityContact.contact_role.name_nl }}</span>
        </div>
      </div>
    </div>
    <hr v-if="contactRelationships.length"  class="tw-my-4" />
    <div class="tw-grid md:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-4">
      <div
        v-for="contactRelationship in contactRelationships"
        :key="contactRelationship.id"
        :class="['tw-flex tw-gap-x-3 tw-rounded tw-border tw-p-2', isOverriddenByEntityContact(contactRelationship) ? 'tw-text-gray-400' : '']"
      >
        <div>
          <span class="tw-font-bold">
            {{ contactRelationship.acting_contact.display_name }}
            <router-link :to="{ name: 'ContactDetails', params: { id: contactRelationship.acting_contact.id } }" target="_blank">
              <i class="fa fa-link" />
            </router-link>
          </span>
          <br>
          <span class="tw-italic tw-text-xs">
            {{ contactRelationship.role.name_nl }} van <u>{{ contactRelationship.acted_upon_contact.display_name }}</u>
          </span>
        </div>
      </div>
    </div>
    <EntityContactsCreateUpdateModal
      ref="entityContactsCreateUpdateModal"
      :entity-type="entityType"
      :entity-id="entityId"
      @created="loadEntityContacts"
      @updated="loadEntityContacts"
    />
  </PanelBox>
</template>

<script>
import PanelBox from '@/components/iam/PanelBox'
import EntityContactsCreateUpdateModal from '@/components/properties/EntityContactsCreateUpdateModal.vue'
import { successModal, errorModal, questionModal } from '@/modalMessages'
import { getPropertyContacts, getPropertyContactRelationships, deletePropertyContact } from '@/services/properties'
import { getProjectContacts, getProjectContactRelationships, deleteProjectContact } from '@/services/projects'

export default {
  name: 'EntityContacts',
  components: {
    EntityContactsCreateUpdateModal,
    PanelBox
  },
  props: {
    entityType: {
      type: String
    },
    entityId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      entityContacts: [],
      contactRelationships: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      return await Promise.all([this.loadEntityContacts(), this.loadEntityContactRelationships()])
    },
    async loadEntityContacts () {
      try {
        // Owners, renters, buyers, should be listed separately
        const excludeRoleKeys = ['owner', 'renter', 'buyer']
        const payload = { entityId: this.entityId, params: { exclude_roles: excludeRoleKeys } }
        const response = this.entityType === 'property'
          ? await getPropertyContacts(payload)
          : await getProjectContacts(payload)
        this.entityContacts = response.data?.results
        return response
      } catch (error) {
        console.error(error)
      }
    },
    async loadEntityContactRelationships () {
      const response = this.entityType === 'property'
        ? await getPropertyContactRelationships(this.entityId)
        : await getProjectContactRelationships(this.entityId)
      this.contactRelationships = response?.data?.results
      return response
    },
    createEntityContact () {
      this.$refs.entityContactsCreateUpdateModal.show(null)
    },
    updateEntityContact (entityContact) {
      this.$refs.entityContactsCreateUpdateModal.show(entityContact)
    },
    isOverriddenByEntityContact (contactRelationship) {
      const contactRelationshipRoleKey = contactRelationship.role?.key
      const entityContact = this.entityContacts.find(entityContact => entityContact.contact_role?.key === contactRelationshipRoleKey)
      return !!entityContact
    },
    async deleteEntityContact (entityContact) {
      const result = await questionModal('Ben je zeker dat je dit contact wilt verwijderen?')
      if (result.value) {
        try {
          const response = this.entityType === 'property'
            ? await deletePropertyContact(this.entityId, entityContact.id)
            : await deleteProjectContact(this.entityId, entityContact.id)
          successModal('Het contact werd verwijderd.')
          await this.loadEntityContacts()
          return response
        } catch (error) {
          errorModal('Er is een fout opgetreden bij het verwijderen van het contact. De reden hiervoor kan zijn dat dit contact gelinkt is aan een factuur voor dit pand.')
          console.error(error)
        }
      }
    }
  }
}
</script>

<template>
  <PanelBox
    v-if="subProperties.length"
    title="Subdossiers"
  >
    <span slot="toolbar">
      <button
        type="button"
        class="toolbar-button"
        @click="showPropertySubPropertyModal"
      >
        <i class="fas fa-plus" /> Toevoegen
      </button>
    </span>
    <table class="tw-w-full tw-border-0.5">
      <thead>
        <tr class="tw-border-0.5">
          <td class="tw-p-1 tw-font-semibold">Referentie</td>
          <td class="tw-font-semibold">Adres</td>
          <td class="tw-font-semibold">Kantoor en medewerker</td>
          <td class="tw-font-semibold">Status</td>
        </tr>
      </thead>
      <tbody>
        <tr class="tw-border-0.5" v-for="subProperty in subProperties" :key="subProperty.id">
          <td class="tw-p-1">
            <router-link
              :to="{ name: 'PropertyDetails', params: { id: subProperty.id } }"
              target="_blank"
            >
              {{ subProperty.reference }}
            </router-link>
          </td>
          <td>{{ subProperty.address }}</td>
          <td>{{ subProperty.office.reference }} | {{ subProperty.collaborator.first_name }} {{ subProperty.collaborator.last_name }}</td>
          <td>{{ subProperty.status_display }}</td>
          <td class="tw-pr-1">
            <button
              type="button"
              @click="unlinkSubProperty(subProperty)"
            >
              <i class="fa fa-trash tw-text-red-500" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <PropertySubPropertyModal
      ref="modal"
      :property-id="propertyId"
      title="Subdossier toevoegen"
      @subPropertyLinked="fetchPropertySubProperties"
    />
  </PanelBox>
</template>

<script>
import EventBus from '@/components/iam/bus'
import PanelBox from '@/components/iam/PanelBox'
import PropertySubPropertyModal from '@/components/properties/PropertySubPropertyModal'
import { questionModal, successModal } from '@/modalMessages'

import { getPropertySubProperties, updateProperty } from '@/services/properties'

export default {
  name: 'PropertySubPropertiesDisplayBox',
  components: {
    PanelBox,
    PropertySubPropertyModal
  },
  props: {
    propertyId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      subProperties: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      return await this.fetchPropertySubProperties()
    },
    async fetchPropertySubProperties () {
      const response = await getPropertySubProperties(this.propertyId)
      this.subProperties = response.data?.results
      return response
    },
    async unlinkSubProperty (subProperty) {
      const result = await questionModal(`Wil je dossier ${subProperty.reference} ontkoppelen van het moederpand?`)
      if (!result.value) return
      const response = await updateProperty(
        subProperty.id, { parent: null, is_managed_by_parent: false }
      )
      successModal(`Dossier ${subProperty.reference} werd succesvol ontkoppeld`)
      await this.fetchPropertySubProperties()
      if (!this.subProperties.length) EventBus.$emit('initProperty') // The button "Set property as parent" should be visible under "Meer" in "PropertyLayout"
      return response
    },
    showPropertySubPropertyModal () {
      this.$refs.modal.show()
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseModal',{ref:"modal",attrs:{"title":"Publiceren"},on:{"hide":_vm.destroySlider}},[_c('FormulateForm',{attrs:{"name":"publicerenForm"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('h2',{staticClass:"tw-mt-0"},[_vm._v("Checks")]),_vm._l((_vm.checks),function(check){return _c('div',{key:check.key,staticClass:"tw-text-sm tw-mb-2"},[_c('i',{class:[
          'fas tw-mr-2 tw-text-center tw-text-base',
          check.status
            ? 'fa-badge-check tw-text-primary'
            : check.blocking
              ? 'fa-times-circle tw-text-error'
              : 'fa-exclamation-circle tw-text-warning'
          ]}),_vm._v(" "+_vm._s(check.label)+" "),(check.details)?[_c('br'),_c('span',{staticClass:"tw-ml-7 tw-italic tw-text-xs"},[_vm._v(_vm._s(check.details))])]:_vm._e()],2)}),_c('h2',{attrs:{"clas":"tw-mt-0"}},[_vm._v("Wettelijke vermeldingen")]),_vm._l((_vm.legalChecks),function(legalCheck){return _c('div',{key:legalCheck.label,staticClass:"tw-text-sm tw-mb-2"},[_c('i',{class:[
          'fas tw-mr-2 tw-text-center tw-text-base',
          legalCheck.status === 'complete'
            ? 'fa-badge-check tw-text-primary'
            : legalCheck.status === 'not_entered' || legalCheck.status === 'in_request_without_date'
              ? 'fa-times-circle tw-text-error'
              : 'fa-exclamation-circle tw-text-warning'
          ]}),_vm._v(" "+_vm._s(legalCheck.label)+" ")])}),_c('h2',{attrs:{"clas":"tw-mt-0"}},[_vm._v("Publieke documenten")]),_vm._l((_vm.documents),function(document){return _c('div',{key:document.label,staticClass:"tw-text-sm tw-mb-2"},[_c('i',{class:[
          'fas tw-mr-2 tw-text-center tw-text-base',
          document.status === 'exists'
            ? 'fa-badge-check tw-text-primary'
            : document.blocking && document.status !== 'in_request'
              ? 'fa-times-circle tw-text-error'
              : 'fa-exclamation-circle tw-text-warning'
          ]}),_vm._v(" "+_vm._s(document.label)+" "),(document.url)?_c('a',{staticClass:"tw-ml-1",attrs:{"href":("" + (document.url))}},[_c('i',{staticClass:"fa fa-download tw-text-[10px]"})]):_vm._e()])}),_c('div',[_c('div',{staticClass:"tw-w-full"},[_c('h2',[_vm._v("Belangrijk voor facturatie")]),_c('table',{staticClass:"tw-my-4 tw-w-full tw-table-auto"},[_c('tr',[_c('th',{staticClass:"tw-w-1/2"},[_vm._v("Primair kantoor")]),_c('td',{staticClass:"tw-w-1/2"},[_vm._v(_vm._s(_vm.primaryOffice.reference)+" "+_vm._s(_vm.primaryOffice.name))])])])]),_c('div',{staticClass:"tw-w-full"},[_c('h2',[_vm._v("Informatie")]),_c('table',{staticClass:"tw-my-4 tw-w-full tw-table-auto"},[_c('tr',[_c('th',{staticClass:"tw-w-1/2"},[_vm._v("Prijs")]),_c('td',{staticClass:"tw-w-1/2"},[_vm._v(_vm._s(_vm.currency(_vm.values.price)))])]),_c('tr',[_c('th',{staticClass:"tw-w-1/2"},[_vm._v("Prijs zichtbaarheid")]),_c('td',{staticClass:"tw-w-1/2"},[_vm._v(_vm._s(_vm.values.publish_price ? 'Publiceer prijs' : 'Publiceer prijs niet'))])]),_c('tr',[_c('th',{staticClass:"tw-w-1/2"},[_vm._v("Adres zichtbaarheid")]),_c('td',{staticClass:"tw-w-1/2"},[_vm._v(_vm._s(_vm.ADDRESS_PUBLISH_OPTIONS[_vm.values.publish_address]))])]),_c('tr',[_c('th',{staticClass:"tw-w-1/2"},[_vm._v("Concept")]),_c('td',{staticClass:"tw-w-1/2"},[_vm._v(_vm._s(_vm.concept.name))])])])])]),(_vm.confirmEpc)?_c('FormulateInput',{attrs:{"type":"checkbox","name":"confirm_epc","label":"Ik bevestig dat voor dit pand geen EPC van toepassing is","validation":"required","validation-messages":{
          required: 'Bevestig alstublieft'
        }},on:{"input":_vm.changeEPCBlocker}}):_vm._e(),_c('div',{ref:"slider",staticClass:"tw-my-4 keen-slider tw-space-x-2"},_vm._l((_vm.pictures),function(picture){return _c('div',{key:picture.id,staticClass:"keen-slider__slide"},[_c('img',{attrs:{"src":picture.url,"alt":"picture"}})])}),0),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-end tw-w-full"},[(_vm.disableSubmit)?_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(_vm.disableSubmit))]):_vm._e(),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","title":"Opslaan","disabled":isLoading || _vm.disableSubmit,"outer-class":"tw-my-2"}},[_c('i',{class:[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-badge-check'
            ]}),_vm._v(" Publiceren ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1),_c('BaseModal',{ref:"portals",attrs:{"title":"Portals"},on:{"hide":_vm.portalsHidden}},[_c('PortalPublications',{ref:"portalPublications",attrs:{"property":_vm.property,"marketing":_vm.marketing}}),_c('FormulateInput',{attrs:{"type":"button","outer-class":"tw-mt-2 tw-float-right tw-mb-6"},on:{"click":_vm.openWizardEntityInvoice}},[_c('i',{staticClass:"fas fa-arrow-right tw-mr-1"}),_vm._v(" Facturatie ")])],1),_c('WizardEntityInvoice',{ref:"wizardEntityInvoice",on:{"wizardEntityInvoiceHidden":_vm.wizardEntityInvoiceHidden}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }